<template>
  <v-app class="bg-white">
    <v-main class="auth-pages" >
      <div  class="header-card" :style="company.background ? 'background-image: url('+base_url_img+'vcard/'+company.background+'); background-size: cover;  background-position: center;-repeat: no-repeat;' : 'background:'+company.color">
        <v-row style="display: flex; justify-content: space-around" >
          <v-col cols="12" style="display: flex; justify-content: space-around;margin-top: 35px;" >
                    <v-avatar color="indigo" class="mt-5" size="100" v-if="company.photo">
                              <img v-if="company"  class="" :src="base_url_img+'vcard/'+company.photo" >
                              </v-avatar>
                </v-col>
                <v-col cols="12" style="display: flex; justify-content: space-around; margin-top: -15px; " >
                    <h1 class=" " style="color: white">{{company.name}}</h1>
                  </v-col>
                  <v-col cols="12" style="display: flex; justify-content: space-around" >
                    <p :class="`text-${model}`" style="color: white;margin-top: -25px;">{{company.position}}</p>
                  </v-col>
                  <v-col cols="12"  style="display: flex;margin-top: -35px; justify-content: space-around; margin-bottom: 35px;" >
                    <h5 class="h4 font-weight-medium" style="color: white;opacity: 0.55;">{{company.business_name}}</h5>
                  </v-col>
                </v-row>
                </div>
                <v-row style="background-color: rgb(230, 230, 230);margin: 0px;">
                  <v-col cols="6" md="6" style="padding-right: 0px;padding: 0px;" v-for="item in company.items" :key="item.id">
                      <a class="cards" :href="item.link">
                        <v-row style="display: flex; justify-content: space-around;margin-top: -75px;" >
                                <v-col cols="12" style="display: flex; justify-content: space-around;margin-top: 15px; padding: 0px;" >
                                  <v-icon size="55" class="material-icons-round" style="color:black" >
                                      {{getIcon(item.type)}}
                                    </v-icon>
                                      </v-col>
                                    
                                        <v-col cols="12"  style="display: flex; justify-content: space-around; margin-bottom: 0px;" >
                                          <h5 class="h4 font-weight-medium  text-uppercase" style="color: black;">{{item.label}}</h5>
                                        </v-col>
                                        <v-col cols="12" style="display: flex; justify-content: space-around" >
                                          <p :class="`text-${model}`" style="color: black;margin-top: -25px; margin-bottom: -30px;">{{item.value}}</p>
                                        </v-col>
                                       
                                      </v-row>
                      </a>
                </v-col>
               
                </v-row>
                <v-row >
                  <v-col cols="12" style="padding-right: 55px;padding-left: 55px;padding-bottom: 0px;">
                    <v-btn
                        class="ma-2"
                        outlined
                        button
                        @click="overlay = true"
                        block 
                        tile
                        large
                        color="indigo"
                      >
                        {{company.qr_code_button}}
                      </v-btn>
                  </v-col>
                  <v-col cols="12" style="padding-right: 55px;padding-left: 55px;padding-bottom: 0px;">
                    <v-btn
                        class="ma-2"
                        outlined
                        button
                        block 
                        tile
                        large
                        color="indigo"
                      >
                        {{company.save_button}}
                      </v-btn>
                  </v-col>
                  <v-col cols="12" style="padding-right: 55px;padding-left: 55px;padding-bottom: 0px;">
                    <v-btn
                        class="ma-2"
                        outlined
                        button
                        block 
                        tile
                        large
                        color="indigo"
                      >
                        {{company.add_to_homecreeen}}
                      </v-btn>
                  </v-col>
                  <v-col cols="12" style="padding-right: 55px;padding-left: 55px;padding-bottom: 0px;">
                    <v-btn
                        class="ma-2"
                        outlined
                        button
                        block 
                        tile
                        large
                        color="indigo"
                      >
                        {{company.share_button}}
                      </v-btn>
                  </v-col>
                  <v-col cols="12" style="padding-right: 55px;padding-left: 55px;padding-bottom: 0px;margin-bottom: 15px;">
                    <v-btn
                        class="ma-2"
                        outlined
                        button
                        block 
                        tile
                        large
                        color="indigo"
                      >
                        {{company.own_card}}
                      </v-btn>
                  </v-col>
                  
                  
                  
                  </v-row>
                  <v-dialog
                      v-model="overlay"
                      width="500"
                    >
                    <img v-if="company"  class="" :src="base_url_img+'vcard/'+company.id+'-QR.png'" width="50">
                  
                  
                  </v-dialog>
           
    </v-main>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import VCard from 'vcard-creator'
import { FadeTransition } from "vue2-transitions";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
    VCard
  },
  data() {
    return {
       checkbox: false,
       showpassword : false,
       email : '',
      password : '',
      overlay : false,
      showPassword : false,
      dialog: false,
      pageFlag: 'init',
      backgroundLogo: localStorage.getItem('admin_bg'),
      isDark :true,
      buttonFlag   : false,
      loadingLogo : false,
      url : '',
      company : '',
      loadingFlag : false,
      base_url_img : process.env.VUE_APP_WASBI_URL
    };
  },
  beforeCreate: function () {
    
},
created: function () {

      this.getCompanyInfo(this.url)

},
methods: {
gotohome: function () {
    let self = this;
    self.$router.push({ name: "Home" });
},
getCompanyInfo(){
  let id = this.$route.params.id;
  axios({
          method: "POST",
           
          url: "/api/get-card",
          data: {
            url: id
          }
        })
          .then(response => {
            this.company = response.data.data;
            this.company.items = JSON.parse(JSON.parse(this.company.items))
            //this.loadingFlag = true;
          })

},
checkFormSignup(){
  let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        localStorage.setItem('temp_email',this.email)
        localStorage.setItem('temp_pass',this.password)
        localStorage.setItem('email_verified',0)
        if(this.company)
          this.$router.push('/signup/'+this.url)
        else
          this.$router.push('/signup')
      }
},
forgotPass(){
    this.buttonFlag =true;
    this.isDark = false;
    axios({
          method: "POST",
           
          url: "/api/auth/forgot-password",
          data: {
            email: this.email,
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Forgot Password Mail sent', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.isDark = true;
                this.pageFlag = 'login';
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
},
gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},gotoGoogle(){
      let self = this;
        this.$router.push('/redirect/google');
        location.reload();
  },
  gotoMs(){
      let self = this;
        this.$router.push('/redirect/microsoft');
        location.reload();
  },
  gotoYahoo(){
      let self = this;
        this.$router.push('/redirect/yahoo');
        location.reload();
  },
  exportToFile(card, name='contact', force=true) {
            const a = document.createElement('a');
            const file = new Blob([card], { type: 'text/vcard'});

            a.href = URL.createObjectURL(file);
            a.download = name;
            a.click();

            URL.revokeObjectURL(a.href);
  },
  getIcon(id){
    if(id == 'Phone')
      return 'fa-phone';
    if(id == 'Email')
      return 'mail';
    if(id == 'Whatsapp')
      return 'fab fa-whatsapp';
    if(id == 'SMS/Text')
      return 'fas fa-comments';
    if(id == 'Website')
      return 'fas fa-link';
    if(id == 'Instagram')
      return 'fab fa-instagram';
    if(id == 'Snapchat')
      return 'fab fa-snapchat';
    if(id == 'Twitter')
      return 'fab fa-twitter';
    if(id == 'Facebook')
      return 'fab fa-facebook';
    if(id == 'LinkedIN')
      return 'fab fa-linkedin';
    else
      return 'fas fa-link';
  },
/**
 * checkForm
 * login the user with valid email/pass
 */
checkForm: function(e) {

      let flag = 1;
      let id = this.$route.params.id;
      if (!id) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      
      if (flag == 1) {
        axios({
          method: "POST",
           
          url: "/api/auth/add-contact",
          data: {
            id: id
          }
        })
          .then(response => {
            let user = response.data.data;
            user.instagram = "http://instagram.com/"+ user.instagram
            user.facebook = "http://www.facebook.com/profile.php?id=" + user.facebook
            user.youtube = "http://www.youtube.com/user/"+ user.youtube
            user.linkedin = "http://www.linkedin.com/in/"+ user.linkedin
            user.website = "http://instagram.com/"+ user.instagram
            user.twitter = "http://twitter.com/"+ user.twitter
            user.pinterest= "http://pinterest.com/"+ user.pinterest
            user.tiktok= "http://www.tiktok.com/@"+ user.tiktok
            user.snapchat=  "http://snapchat.com/add/"+ user.snapchat
            user.reddit= "http://www.reddit.com/user/"+ user.reddit
            user.tumblr= "http://tumblr.com/"+ user.tumblr
            user.flickr= "http://www.flickr.com/photos/"+ user.flickr
            user.vine= "http://vine.co/"+ user.vine
            user.medium= "http://medium.com/@"+ user.medium
            user.soundcloud= "http://soundcloud.com/"+ user.soundcloud
            user.twitch= "http://www.twitch.tv/"+ user.twitch
            user.clubhouse= " https://www.joinclubhouse.com/@"+ user.clubhouse
            const myVCard = new VCard()
            myVCard
            .addName(user.last_name, user.first_name)
            .addCompany(user.business_name)
            .addEmail(user.email)
            .addPhoneNumber(user.phone)
            .addSocial(user.instagram, 'Instagram')
            .addSocial(user.facebook, 'Facebook')
            .addSocial(user.linkedin, 'LinkedIn')
            .addSocial(user.youtube, 'Youtube')
            .addSocial(user.twitter, 'Twitter')
            .addSocial(user.pinterest, 'Pinterest')
            .addSocial(user.tiktok, 'Tiktok')
            .addSocial(user.snapchat, 'Snapchat')
            .addSocial(user.reddit, 'Reddit')
            .addSocial(user.tumblr, 'Tumblr')
            .addSocial(user.flickr, 'Flickr')
            .addSocial(user.vine, 'Vine')
            .addSocial(user.medium, 'Medium')
            .addSocial(user.soundcloud, 'Soundcloud')
            .addSocial(user.clubhouse, 'Clubhouse')

            .addAddress(user.address)
            .addURL(user.website)
            
            const output = myVCard.toString()
            console.log(output);
            const preElement = document.querySelector('#output')
            const outputNode = document.createTextNode(output)
            this.exportToFile(output,'contact',true)
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }
    }
  }
    
};
</script>
<style>
  .check-login .v-label {
    color: white;
  }
  .check-login .v-icon {
    color: white;
  }
.input-login .v-icon{
    color: #FDE038!important;
}
.input-login .v-label{
  color: #FDE038!important;
}
.input-login #input-6{
  color: #FDE038!important;
}
.input-login #input-10{
  color: #FDE038!important;
}
.header-card{
  overflow: hidden;
  position: relative;
  z-index: 3;
  border-bottom: 1px solid rgb(217, 218, 220);
  color: rgb(20, 20, 20);
  background: linear-gradient(45deg, rgb(255, 117, 140), rgb(255, 126, 179));
}
.cards{
  box-sizing: border-box;
  position: relative;
  display: block;
  padding: 80px 10px 30px;
  background-color: rgb(230, 230, 230);
  border-bottom: 1px solid rgb(217, 218, 220);
  text-align: center;
  cursor: pointer;
  user-select: none;
  color: rgb(20, 20, 20);
}
.cards:nth-child(2n+1) {
  border-right: 1px solid rgb(217, 218, 220);
}
.cards:hover {
  color: skyblue!important;
  background: #D9E6F4;
}
</style>