<template>
<v-app id="inspire">

<vue-header-admin></vue-header-admin>
<v-main>
    <v-container fluid class="px-6 py-6" >
        <v-row
            align="center"
            style="margin-bottom: 80px!important;"
            justify="center"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                <v-card-text style="margin-bottom: -30px;">
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Card <span v-if="company.id">({{ company.name }})</span> <v-icon class="material-icons-round" style="float:right; color:orange;font-size: 25px;" v-if="company.id" @click="gotoLink(company.card_url)">
                        visibility
                            </v-icon></h5>
                    <h5 class="text-h5 text-typo" v-if="company.id">Card ID: <span v-if="company.id">{{ company.id }}</span></h5>
                    </v-col>
                    <v-row class="mt-2">
                      <v-col sm="6" cols="12" class="py-0">
                        <v-select
                              label="Color Scheme"
                              outlined
                              dense
                              class="font-size-input input-style"
                              v-model="company.color"
                              :items="['Orange','Green','Purple','Red','Pink','Blue','Dark Blue','Black','Silver','Hot Pink','Flat Black']"
                              ></v-select>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-select
                              label="Heading Text Color"
                              outlined
                              dense
                              class="font-size-input input-style"
                              v-model="company.header_color"
                              :items="['Light Text','Dark Text',]"
                              ></v-select>
                      </v-col>
                      <v-col sm="12" cols="12" class="py-0">
                        <v-text-field
                          label="Full Name*"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          :rules="rules"
                          v-model="company.name"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="12" cols="12" class="py-0">
                        <v-text-field
                        :label="'Card URL ('+base_url+'/card/<url>)'"
                        outlined
                        dense
                        color="#e91e63"
                        :rules="rules"
                                class="font-size-input input-style"
                                v-model="company.card_url"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                     <v-row class="mt-2">
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Position/Job"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          v-model="company.position"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                        outlined
                        dense
                          label="Company (Optional)"
                          color="#e91e63"
                          v-model="company.business_name"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                       
                        <avatar-cropper
                          :upload-handler="handleUploaded"
                          :output-mime="cropperOutputMime"
                          v-model="changeTrigger"
                          />

                      <template >
                        <v-img contain  height="50" width="50" style="display:inline-block;margin-right: 35px;" v-if="!preview" src="@/assets/img/avatar.png" class="img-fluid" />
                        <v-img contain height="50" width="50" style="display:inline-block;margin-right: 35px;" v-else   :src="preview" class="img-fluid" />
                        <v-btn
                              type="button"
                              color="blue"
                              small
                              style="margin-top: -20px; margin-right: 15px;;"
                              :loading="isDarkUpload"
                              class="
                                font-weight-bolder
                                btn-default
                                mb-5
                                px-8
                                ms-auto
                              "
                              @click="changeTrigger = true"
                            >
                              Select Photo
                            </v-btn>
                            <v-btn
                              type="button"
                              color="red"
                              small
                              v-if="preview"
                              style="margin-top: -20px;"
                              :loading="isDarkUpload"
                              class="
                                font-weight-bolder
                                btn-default
                                mb-5
                                px-8
                                ms-auto
                              "
                              @click="removePhoto('photo')"
                            >
                              Remove Photo
                            </v-btn>
                      </template>
                        <!-- <v-file-input
                            label="Photo"
                            color="#e91e63"
                            outlined
                            dense
                            required
                            accept="image/*"
                            :rules="rules"
                            v-model="company.photo"
                            class="font-size-input input-style"
                          ></v-file-input> -->
                      </v-col>
                      
                      <v-col sm="6" cols="12" class="py-0">
                        <avatar-cropper
                          :cropper-options="{aspectRatio: 16 / 9}"
                          :upload-handler="handleUploaded1"
                          :output-mime="cropperOutputMime1"
                          v-model="changeTrigger1"
                          />

                      <template >
                        <v-img contain  height="50" width="50" style="display:inline-block;margin-right: 35px;" v-if="!preview1" src="@/assets/img/avatar.png" class="img-fluid" />
                        <v-img contain height="50" width="50" style="display:inline-block;margin-right: 35px;" v-else   :src="preview1" class="img-fluid" />
                        <v-btn
                              type="button"
                              color="blue"
                              small
                              style="margin-top: -20px;margin-right: 15px;"
                              :loading="isDarkUpload"
                              class="
                                font-weight-bolder
                                btn-default
                                mb-5
                                px-8
                                ms-auto
                              "
                              @click="changeTrigger1 = true"
                            >
                              Select Background
                            </v-btn>
                            <v-btn
                              type="button"
                              color="red"
                              small
                              v-if="preview1"
                              style="margin-top: -20px;"
                              :loading="isDarkUpload"
                              class="
                                font-weight-bolder
                                btn-default
                                mb-5
                                px-8
                                ms-auto
                              "
                              @click="removePhoto('background')"
                            >
                              Remove Photo
                            </v-btn>
                      </template>

                      </v-col>
                      <v-col sm="12" cols="12" class="py-0">
                        <avatar-cropper
                          :upload-handler="handleUploaded2"
                          :output-mime="cropperOutputMime2"
                          v-model="changeTrigger2"
                          />

                      <template >
                        <v-img contain  height="50" width="50" style="display:inline-block;margin-right: 35px;" v-if="!preview2" src="@/assets/img/avatar.png" class="img-fluid" />
                        <v-img contain height="50" width="50" style="display:inline-block;margin-right: 35px;" v-else   :src="preview2" class="img-fluid" />
                        <v-btn
                              type="button"
                              color="blue"
                              small
                              style="margin-top: -20px;margin-right: 15px;"
                              :loading="isDarkUpload"
                              class="
                                font-weight-bolder
                                btn-default
                                mb-5
                                px-8
                                ms-auto
                              "
                              @click="changeTrigger2 = true"
                            >
                              Select QR Code
                            </v-btn>
                            <v-btn
                              type="button"
                              color="red"
                              small
                              v-if="preview2"
                              style="margin-top: -20px;"
                              :loading="isDarkUpload"
                              class="
                                font-weight-bolder
                                btn-default
                                mb-5
                                px-8
                                ms-auto
                              "
                              @click="removePhoto('qr')"
                            >
                              Remove Photo
                            </v-btn>
                      </template>

                      </v-col>
                      <v-col sm="2" cols="12"><label>Background Video</label></v-col>
                          <v-col sm="4" cols="12" class="py-0" v-if="!company.video_background">
                            
                            <v-select
                              :items="['URL','File']"
                              label="Video Type"
                              outlined
                              v-model="company.video_type"
                              class="font-size-input input-style"
                              dense
                              density="compact"
                            >
                           
                          </v-select>
                          </v-col>
                          <v-col sm="4" cols="12" class="py-0" v-if="!company.video_background">
                            
                            <v-text-field
                            label="URL"
                            color="#e91e63"
                            outlined
                            dense
                            v-if="company.video_type == 'URL'"
                            required
                            :rules="rules"
                            v-model="company.video_url"
                            class="font-size-input input-style"
                          ></v-text-field>
                          <v-file-input
                            label="File"
                            color="#e91e63"
                            outlined
                            v-if="company.video_type == 'File'"
                            dense
                            required
                            accept="video/*"
                            :rules="rules"
                            v-model="video_file"
                            class="font-size-input input-style"
                          ></v-file-input>
                          </v-col>
                          <v-col sm="2" cols="12" v-if="company.video_background"><label> {{ company.video_background }} </label></v-col><v-col sm="4" cols="12" class="py-0" v-if="company.video_background">
                            <v-btn
                              type="button"
                              color="red"
                              small
                              :loading="isDarkUpload"
                              class="
                                font-weight-bolder
                                btn-default
                                mb-5
                                px-8
                                ms-auto
                              "
                              @click="removePhoto('video-background')"
                            >
                              Remove Video Background
                            </v-btn>
                          </v-col>
                          <v-col sm="6" cols="6"> <v-text-field
                                          label="Photo Height"
                                          color="#e91e63"
                                          required
                                          outlined
                                          dense
                                          v-model="company.photo_height"
                                          class="font-size-input input-style"
                                        ></v-text-field></v-col>
                                        <v-col sm="6" cols="6"> <v-text-field
                                          label="Photo Width"
                                          color="#e91e63"
                                          required
                                          outlined
                                          dense
                                          v-model="company.photo_width"
                                          class="font-size-input input-style"
                                        ></v-text-field></v-col>
                      <v-col sm="12" cols="12" class="py-0 button-label" >
                        <h3>Contact Points</h3>
                      </v-col>
                      <v-col sm="12" cols="12" class="mt-3 mb-2 button-label" v-for="(item,key) in company.links" :key="key">
                        <v-expansion-panels v-model="item.id" >
                            <v-expansion-panel   v-model="item.id">
                              <v-expansion-panel-header><v-icon class="material-icons-round" style="float:left!important;display: contents;color:red" v-if="key != 0" @click="remove(key)">
                              close
                            </v-icon></v-expansion-panel-header>
                              <v-expansion-panel-content key="0">
                                <v-row class="mt-2">
                                  <v-col sm="6" cols="12" class="py-0">
                                    <v-select
                                          label="Type"
                                          outlined
                                          dense
                                          @change="renderInputField(key)"
                                          class="font-size-input input-style"
                                          v-model="item.type"
                                          :items="['Phone','Whatsapp','SMS/Text','Email','Website','Zoom','Event','Instagram','Snapchat','Twitter','Facebook','LinkedIN','Clubhouse','Cash App','Venmo','Zelle Transfer','Peymynt','Paypal','Stripe']"
                                          ></v-select>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="py-0">
                                      <v-text-field
                                          label="Label"
                                          color="#e91e63"
                                          required
                                          outlined
                                          dense
                                          v-model="item.label"
                                          class="font-size-input input-style"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="py-0">
                                      <v-text-field
                                          label="Value"
                                          color="#e91e63"
                                          required
                                          outlined
                                          dense
                                          @input="populateText(item)"
                                          v-model="item.value"
                                          class="font-size-input input-style"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="py-0">
                                      <v-text-field
                                          label="URL/Href"
                                          color="#e91e63"
                                          required
                                          outlined
                                          dense
                                          v-model="item.link"
                                          class="font-size-input input-style"
                                        ></v-text-field>
                                    </v-col>
                                    </v-row>
                                    </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    </v-expansion-panels>
                                    </v-col>
                                    <v-col sm="12" cols="12" class="py-0 button-label" >
                                      <v-btn
                                            color="#4E5FBB"
                                            block
                                            @click="add()"
                                            class="
                                              font-weight-bolder
                                              btn-default
                                              mb-5
                                              px-8
                                              ms-auto
                                            "
                                            
                                          >
                                          <v-icon class="material-icons-round" large >
                                                  add
                                                </v-icon>
                                          </v-btn>
                                    </v-col>
                      <v-col sm="12" cols="12" class="py-0 button-label" >
                        <v-expansion-panels v-model="panel" >
                            <v-expansion-panel   v-model="panel">
                              <v-expansion-panel-header>Button Labels</v-expansion-panel-header>
                              <v-expansion-panel-content key="0">
                                <v-row>
                                  <v-col sm="6" cols="12" class="py-0">
                                    <v-text-field
                                      label="View QR code button"
                                      color="#e91e63"
                                      required
                                      outlined
                                      dense
                                      v-model="company.qr_code_button"
                                      class="font-size-input input-style"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col sm="6" cols="12" class="py-0">
                                    <v-text-field
                                      label="Save button"
                                      color="#e91e63"
                                      required
                                      outlined
                                      dense
                                      v-model="company.save_button"
                                      class="font-size-input input-style"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col sm="6" cols="12" class="py-0">
                                    <v-text-field
                                      label="Share button"
                                      color="#e91e63"
                                      required
                                      outlined
                                      dense
                                      v-model="company.share_button"
                                      class="font-size-input input-style"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col sm="6" cols="12" class="py-0">
                                    <v-text-field
                                      label="Add to homescreeen button"
                                      color="#e91e63"
                                      required
                                      outlined
                                      dense
                                      v-model="company.add_to_homecreeen"
                                      class="font-size-input input-style"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col sm="6" cols="12" class="py-0">
                                    <v-text-field
                                      label="Get your own card button"
                                      color="#e91e63"
                                      required
                                      outlined
                                      dense
                                      v-model="company.own_card"
                                      class="font-size-input input-style"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>


                                </v-expansion-panel-content>
                                </v-expansion-panel>
                                </v-expansion-panels>
                      </v-col>
                    <v-col
                    cols="12"
                    md="12"
                    >

                 <v-card-actions class="justify-center ">
                  <v-row>
                    <v-col>
                      <v-btn
                        color="#4CAF50"
                        large
                        :loading="loading"
                        @click="saveProfile"
                        class="
                        float-right
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          ms-auto
                        "
                        
                      >
                        Save
                      </v-btn>
                      </v-col>
                    </v-row>
                </v-card-actions>
                    </v-col>

                    
                  </v-row>
                </v-card-text>
                <!-- <v-card-actions class="justify-center ">
                  <v-btn
                    tile  dark
                    style="height: 49px;width:95px;"
                    color="primary"
                    class="mb-10"
                    @click="saveProfile"
                  >Save</v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
    </v-container>
    
     <vue-footer-admin></vue-footer-admin>
     </v-main>
</v-app>

</template>

<script>
import AvatarCropper from 'vue-avatar-cropper'
import { VueTelInput } from 'vue-tel-input'
export default {
components: {
      VueTelInput,AvatarCropper
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        name:'',
        preview : '',
        preview2: '',
        cropperOutputMime : '',
        preview1 : '',
        cropperOutputMime1 : '',
        cropperOutputMime2 : '',
        panel : 0,
        changeTrigger : false,
        changeTrigger1 : false,
        changeTrigger2 : false,
        panel1 : 0,
        phone:'',
        rules: [
                value => !!value || 'Required.',
            ],
        loading : false,
        password : '',
        is_admin:0,
        confpassword : '',
        role : '',
        companyname : '',
        sector : '',
        no_of_employee : '',
        showPassword : false,
        phoneFlag:0,
        base_url: window.location.origin,
        emailFlag:0,
        country :'',
        otp_email:'',
        otp_phone:'',
        stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
        company : {
                id: '',
                name : "",
                business_name : '',
                position : '',
                color : '',
                photo : [],
                card_url : '',
                background : [],
                photo_height :250,
                photo_width :250,
                qr_image : [],
                header_color : 'Light Text',
                qr_code_button : 'Scan my QR Code',
                save_button :'Add to contacts',
                share_button : 'Share my card',
                add_to_homecreeen : 'Add to home screen',
                own_card : "Start your 7-day free trial",
                links: [{
                  id: 0,
                  type : 'Phone',
                  label : 'Phone',
                  link : 'tel:',
                  value : ''
                }],


        },
        otp_phone_input:"",
        otp_email_input:"",
        video_file : '',
        user_phone:'',
        user_email:'',
        status:0,

        tab: null,
        headers: [

          { text: '', value: 'CompanyURL' },
          { text: '', value: 'CompanyName' },

        ],
        history: [

        ],

        setting: [
        ],

        items: [
              'Admin',
              'Viewer',
      ],
      select_role : 'Admin',
       sectors: [

      ],
      employeeNumber: [
              '10-50',
              '50-100',
              'More than 100',

      ],

      }
    },
  watch: {
            company: {
                handler () {
                    // this.company.company_url = this.company.name.replace(/\s/g, '-').toLowerCase()
                },
                deep: true,
            },
        },
    methods: {
    addPhone(){
        this.phoneFlag = 1;
    },
    addEmail(){
        this.emailFlag = 1;
    },
    handleUploaded(cropper) {
      let self = this;
          let image = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime);
          let img = this.dataURLtoFile(image,'asdasd.jpg');
          self.preview = image;
          self.company.photo = img
          

        },
        handleUploaded1(cropper) {
      let self = this;
          let image = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime1);
          let img = this.dataURLtoFile(image,'asdasd.jpg');
          self.preview1 = image;
          self.company.background = img
          

        },
        handleUploaded2(cropper) {
      let self = this;
          let image = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime2);
          let img = this.dataURLtoFile(image,'asdasd.jpg');
          self.preview2 = image;
          self.company.qr_image = img
          

        },
  dataURLtoFile(dataurl, filename) {
 
 var arr = dataurl.split(','),
     mime = arr[0].match(/:(.*?);/)[1],
     bstr = atob(arr[1]), 
     n = bstr.length, 
     u8arr = new Uint8Array(n);
     
 while(n--){
     u8arr[n] = bstr.charCodeAt(n);
 }
 
 return new File([u8arr], filename, {type:mime});
},
    add () {
        this.company.links.push({
              id :0,
              type : 'Phone',
              label : 'Phone',
              link : 'tel:',
              value : ''
            })
            console.log(this.company.links);
      },
      
      remove (index) {
          this.company.links.splice(index, 1)
      },
    countryChanged(country) {
        this.country = '+' + country.dialCode
    },
    populateText(item){
      let oldLink = this.renderInputFieldByType(item.type)
      console.log(oldLink);
      item.link = oldLink+item.value
    },
    verify(){
    let self = this;
    let flag = 1;
    if (!self.email && self.emailFlag ==1) {
    Vue.$toast.error('Email '+this.$t('questionnaire.questionnaire_alert_required'), {
        position: 'top-right'
    });
    this.$refs['email'].focus()
    flag = 0;
    }
    let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (reg.test(self.email) == false && flag == 1 && self.emailFlag == 1) {
        Vue.$toast.error('Invalid Email format', {
        position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
    }
    if (!self.phone && flag == 1 && self.phoneFlag == 1) {
    Vue.$toast.error('Phone Number '+this.$t('questionnaire.questionnaire_alert_required'), {
        position: 'top-right'
    });
    this.$refs['phone'].focus()
    flag = 0;
    }

    if (flag == 1) {
    var email = this.email;
    axios({
        method: "POST",
         
        url: "/api/auth/send_otp",
        data: {
        flag : self.phoneFlag == 1 ? 'phone' : 'email',
        email: self.email,
        phone:  self.phone,
        code: self.country,
        logged_flag : 1
        }
    })
        .then(response => {
        let data = response.data;
        if (data.data.flag == 1) {
            if(self.phoneFlag == 1 ){
                self.otp_phone = data.data.otp;
                self.phoneFlag = 2;
            }
            else{
                self.otp_email = data.data.otp;
                self.emailFlag = 2;
            }
            Vue.$toast.success(data.message, {
                position: 'top-right'
            });

        } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
        })
        .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
        });
      }
    },
    gotoLink(id){
      let route = '';
      let url = process.env.VUE_APP_CARD_URL;
      if(this.company.plan == 'Pro'){
        route = this.$router.resolve({path: '/card/'+id});
        window.open(route.href, '_blank');
      }else{
        route = url+'card/'+id;
        window.open(route, '_blank');
      }
    },
    verifyOTP(){
        if(this.phoneFlag ==2){
            if(! this.otp_phone_input){
                Vue.$toast.error('OTP '+this.$t('questionnaire.questionnaire_alert_required'), {
                    position: 'top-right'
                });
            }else{
                if(this.otp_phone == this.otp_phone_input){
                    axios({
                        method: "POST",
                         
                        url: "/api/updateprofile",
                        data: {
                            flag: 'phone',
                            phone: this.phone,
                            code: this.country
                        }
                        })
                        .then(response => {
                            let data = response.data;
                            if (data.data == 1) {
                                Vue.$toast.success(data.message, {
                                    position: 'top-right'
                                })
                                this.getUserProfile()
                                this.phoneFlag = 3;
                            } else {
                                Vue.$toast.error(data.message, {
                                position: 'top-right'
                                });
                            }
                        })
                        .catch(err => {
                            Vue.$toast.error(err, {
                            position: 'top-right'
                            });
                        });


                }else{
                    Vue.$toast.error('Invalid OTP', {
                        position: 'top-right'
                    });
                    this.otp_phone_input ="";
                    this.$refs['phone_otp'].focus()
                }
            }

        }else{
            if(! this.otp_email_input){
                Vue.$toast.error('OTP '+this.$t('questionnaire.questionnaire_alert_required'), {
                    position: 'top-right'
                });
            }else{
                if(this.otp_email == this.otp_email_input){
                     axios({
                        method: "POST",
                         
                        url: "/api/updateprofile",
                        data: {
                            flag: 'email',
                            email: this.email,
                        }
                        })
                        .then(response => {
                            let data = response.data;
                            if (data.data == 1) {
                                Vue.$toast.success(data.message, {
                                    position: 'top-right'
                                })
                                //this.$router.push("/home");
                                this.emailFlag = 3;
                                this.getUserProfile()
                            } else {
                                Vue.$toast.error(data.message, {
                                position: 'top-right'
                                });
                            }
                        })
                        .catch(err => {
                            Vue.$toast.error(err, {
                            position: 'top-right'
                            });
                        });

                } else{
                    Vue.$toast.error('Invalid OTP', {
                        position: 'top-right'
                    });
                    this.otp_email_input ="";
                    this.$refs['email_otp'].focus()
                }
            }
        }
    },
    renderInputField(index){
      let label = '';
      let link = '';
      switch (this.company.links[index].type) {
        case 'Phone':
            label = 'Phone';
            link = 'tel:';
          break;
          case 'Whatsapp':
            label = 'Whatsapp';
            link = 'https://wa.me/';
          break;
          case 'Email':
            label = 'Email';
            link = 'mailto:';
          break;
          case 'SMS/Text':
            label = 'SMS/Text';
            link = 'sms:';
          break;
          case 'Website':
            label = 'Website';
            link = 'https://';
          break;
          case 'Zoom':
            label = 'Zoom';
            link = '';
          break;
          case 'Event':
            label = 'Event';
            link = '';
          break;
          case 'Instagram':
            label = 'Instagram';
            link = 'https://www.instagram.com/';
          break;
          case 'Snapchat':
            label = 'Snapchat';
            link = 'https://www.snapchat.com/add/';
          break;
          case 'Twitter':
            label = 'Twitter';
            link = 'https://www.twitter.com/';
          break;
          case 'Facebook':
            label = 'Facebook';
            link = 'https://www.facebook.com/';
          break;
          case 'LinkedIN':
            label = 'LinkedIN';
            link = 'https://www.linkedin.com/in/';
          break;
          case 'Clubhouse':
            label = 'Clubhouse';
            link = 'https://www.clubhouse.com/';
          break;
          case 'Cash App':
            label = 'Cash App';
            link = 'https://cash.app/';
          break;
          case 'Venmo':
            label = 'Venmo';
            link = 'https://venmo.com/';
          break;
          case 'Zelle Transfer':
            label = 'Zelle Transfer';
            link = '';
          break;
          case 'Peymynt':
            label = 'Peymynt';
            link = '';
          break;
          case 'Paypal':
            label = 'Paypal';
            link = '';
          break;
          case 'Stripe':
            label = 'Stripe';
            link = '';
          break;
        default:
          break;
      }
      this.company.links[index].label = label
      this.company.links[index].link = link
    },
    renderInputFieldByType(type){
      let label = '';
      let link = '';
      switch (type) {
        case 'Phone':
            label = 'Phone';
            link = 'tel:';
          break;
          case 'Whatsapp':
            label = 'Whatsapp';
            link = 'https://wa.me/';
          break;
          case 'Email':
            label = 'Email';
            link = 'mailto:';
          break;
          case 'SMS/Text':
            label = 'SMS/Text';
            link = 'sms:';
          break;
          case 'Website':
            label = 'Website';
            link = 'https://';
          break;
          case 'Zoom':
            label = 'Zoom';
            link = '';
          break;
          case 'Event':
            label = 'Event';
            link = '';
          break;
          case 'Instagram':
            label = 'Instagram';
            link = 'https://www.instagram.com/';
          break;
          case 'Snapchat':
            label = 'Snapchat';
            link = 'https://www.snapchat.com/add/';
          break;
          case 'Twitter':
            label = 'Twitter';
            link = 'https://www.twitter.com/';
          break;
          case 'Facebook':
            label = 'Facebook';
            link = 'https://www.facebook.com/';
          break;
          case 'LinkedIN':
            label = 'LinkedIN';
            link = 'https://www.linkedin.com/in/';
          break;
          case 'Clubhouse':
            label = 'Clubhouse';
            link = 'https://www.clubhouse.com/';
          break;
          case 'Cash App':
            label = 'Cash App';
            link = 'https://cash.app/';
          break;
          case 'Venmo':
            label = 'Venmo';
            link = 'https://venmo.com/';
          break;
          case 'Zelle Transfer':
            label = 'Zelle Transfer';
            link = '';
          break;
          case 'Peymynt':
            label = 'Peymynt';
            link = '';
          break;
          case 'Paypal':
            label = 'Paypal';
            link = '';
          break;
          case 'Stripe':
            label = 'Stripe';
            link = '';
          break;
        default:
          break;
      }
      return link;
    },
    getAddressData: function (addressData, placeResultData, id) {
            this.company.address = addressData.name;
            this.company.city = addressData.locality;
            this.company.state = addressData.administrative_area_level_1;
            this.company.zip = addressData.postal_code
        },
      changePassword: function () {
          let flag = 1;
          if (!this.password) {
            Vue.$toast.error('Password '+this.$t('questionnaire.questionnaire_alert_required'), {
                position: 'top-right'
            });
            this.$refs['password'].focus()
            flag = 0;
          }

          if (!this.confpassword) {
            Vue.$toast.error('Confirm Password '+this.$t('questionnaire.questionnaire_alert_required'), {
                position: 'top-right'
            });
            this.$refs['confpassword'].focus()
            flag = 0;
          }
          if (this.confpassword != this.password && flag == 1) {
            Vue.$toast.error('Confirm password did not match', {
                position: 'top-right'
            });
            self.$refs['conf_password'].focus()
            flag = 0;
        }

          if (flag == 1) {

            axios({
            method: "POST",
             
            url: "/api/passwordchange",
            data: {
            password: this.password,
            password_confirmation: this.confpassword,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.data == 1) {
              Vue.$toast.success(this.$t('my_profile.profile_alert_password_change_successfull'), {
                position: 'top-right'
                });

            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }

    },


    getUserProfile() {
    let flag = 1;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-card-details/"+this.$route.params.id,header)
        .then(response => {
            let data = response.data;
            data.data.links = JSON.parse(JSON.parse(data.data.items))
           this.company = data.data;
           
           if(this.company.photo){
            this.preview =  data.data.api_ref_id && data.data.api_ref_id != 'null'  ? this.company.photo : process.env.VUE_APP_WASBI_URL+'vcard/'+this.company.photo
            this.company.photo = []
           }
          if(this.company.background){
            this.preview1 = data.data.api_ref_id  && data.data.api_ref_id != 'null' ? this.company.background : process.env.VUE_APP_WASBI_URL+'vcard/'+this.company.background
            this.company.background = []
          }
          if(this.company.qr_image){
            this.preview2 = data.data.api_ref_id  && data.data.api_ref_id != 'null' ? this.company.qr_image : process.env.VUE_APP_WASBI_URL+'vcard/'+this.company.qr_image
            this.company.qr_image = []
          }
            
         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },

     removePhoto(id) {
    let flag = 1;
    let self = this;
    if(confirm("Are you sure you want to remove?")){
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .post("/api/remove-card-photo/"+this.$route.params.id,{type : id},header)
        .then(response => {
            let data = response.data;
            self.saveProfile();
            if(id == 'background')
                self.preview1 = ''
            if(id == 'photo')
              self.preview = ''
            if(id == 'qr')
              self.preview2 = ''
            //location.reload();
            
         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
          }

     },


    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.company.name) {
            Vue.$toast.error('Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.card_url && flag == 1) {
            Vue.$toast.error('URL is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.color && flag == 1) {
            Vue.$toast.error('Color Scheme is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.position && flag == 1) {
            Vue.$toast.error('Position is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (this.company.photo.length == 0 && this.company.id == '' && flag == 1) {
            Vue.$toast.error('Photo is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        
    
       
        if (flag == 1) {
          let form  = new FormData();
          for (var key in self.company) {
            if(key != 'links')
              form.append(key,self.company[key]);
          }
          if(self.company.photo.length != 0)
            form.append("file_photo",self.company.photo)
          if(self.company.background && self.company.background.length != 0)
            form.append("file_background",self.company.background.length != 0 ? self.company.background : '')
          if(self.company.qr_image && self.company.qr_image.length != 0)
            form.append("file_qr_image",self.company.qr_image.length != 0 ? self.company.qr_image : '')
          if(self.video_file && self.video_file.length != 0)
            form.append("file_video",self.video_file)
            this.company.links.forEach(element => {
              if(element.type == 'Phone')
                form.append("phone",element.value)
              if(element.type == 'Email')
                form.append("email",element.value)
            });
          
          form.append('links',JSON.stringify(this.company.links));
          self.loading = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-card",
          data: form
        })
          .then(response => {
            let data = response.data;
            if (response.data.flag == 1) {
                  Vue.$toast.success('Card added', {
                    position: 'top-right'
                  })
                  self.loading = false;
                  this.$router.push('/admin/cards')

            } else {
                Vue.$toast.error('URL already exist', {
                position: 'top-right'
                });
                self.loading = false;
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });     
              if(err.response.data.errors.company_url)
                Vue.$toast.error(err.response.data.errors.company_url[0], {
                position: 'top-right'
                });             
          });
        }
    },

 },

created() {
      console.log('created called.');
      if(this.$route.params.id)
        this.getUserProfile();
    },

}
</script>
<style>
.button-label .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
  color: black !important;
}
</style>

