<template>
<v-app id="inspire">

<vue-header-admin></vue-header-admin>
<v-main>
    <v-container fluid class="px-6 py-6">
        <v-row
            align="center"
            style="margin-bottom: 80px!important;"
            justify="center"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl mt-2" id="basic" style="margin-bottom: 100px;">
                <v-card-text style="margin-bottom: -30px;" v-if="!domain.id">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Custom Domain</h5>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        class="font-size-input input-style"
                        label="Domain Name"
                        width="300px"
                        ref="name"
                        v-model="domain.domain"
                        />

                    </v-col>
                    <v-col
                    cols="12"
                    md="12"
                    >
                    <v-card-actions class="justify-center">
                  <v-btn
                      dark
                      large
                    color="grey"
                    class="
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          justify-center
                        "
                    @click="$router.push({ name: 'Dashboard' })"
                  >Back</v-btn>
                   <v-btn
                      dark
                      large
                    color="green"
                    class="
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          justify-center
                        "
                    @click="saveProfile"
                  >Save</v-btn>
                </v-card-actions>
                    </v-col>

                    
                  </v-row>
                </v-card-text>
                <v-card-text style="margin-bottom: -30px;" v-if="domain.id && domain.status == 0">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Custom Domain</h5>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        disabled
                        class="font-size-input input-style"
                        label="Domain Name"
                        width="300px"
                        ref="name"
                        v-model="domain.domain"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                  
                  <!-- <p><strong style="color:red;">Note:</strong> Copy the Server IP <strong style="color:skyblue;">{{server_ip}}</strong> and point domain name to server IP. Then Click on <strong>Verify</strong> Button.</p> -->
            
                  <p ><i><strong >Step 1:</strong> Create a Cloudflare account.</i></p><br>
                  <p ><i> Click +Add a Site</i></p><br>
                  <p ><i> Click the cloud icon at the top left to go to the dashboard.</i></p><br>
                  <p ><i> Enter the site name and click “Add Site”.</i></p><br>
                  <p ><i> You’ll be directed to the plan page, where you can choose the option you prefer. If you scroll to the bottom you can select the free option, and then click Continue. </i></p><br>
                  <p ><i><strong >Step 2:</strong> Configuring DNS records and changing nameservers</i></p><br>
                  <p ><i> On the next page, the system will scan for existing DNS records.</i></p><br>
                  <p ><i> Click Continue to change nameservers.</i></p><br>
                  <p ><i> CloudFlare will tell you where the nameservers already exist. (In the video example, it shows a GoDaddy account. You’d need to log in to your GoDaddy account and change the nameservers over to the Cloudflare.)
                  Note: it is critical to point to Cloudflare’s nameservers. It’s critical for activating your site successfully. Otherwise Cloudflare cannot manage your DNS and optimize your site.</i></p><br>
                  <p ><i> Click “Done, check nameservers” to continue</i></p><br>
                  <p ><i>Walk through the Quick Start Guide to improve security and optimize performance.</i></p><br>
                  <p ><i> As long as you have input all of the nameservers in the domain registrar, then you’re good to go.</i></p><br>
                  <p ><i><strong >Step 3:</strong> Edit DNS</i></p><br>
                  <p ><i> Click DNS link on he left menu in cloudflare. Edit IP for the domain name and point it to IP address {{server_ip}}</i></p><br>
                  <p ><i> When editing, make sure proxy settings is enabled (cloud icon color changes to orange).</i></p><br>

                  <p><strong style="color:skyblue;">Here is a video for steps:</strong> <a target="_blank" href="https://drive.google.com/file/d/1-v-F0mnApw3no66fElOiO9-f9wJFe7Dj/view?usp=sharing">https://drive.google.com/file/d/1-v-F0mnApw3no66fElOiO9-f9wJFe7Dj/view?usp=sharing</a></p>
                  </v-col>
                    <v-col
                    cols="12"
                    md="12"
                    >
                    <v-card-actions class="justify-center">
                  <v-btn
                      dark
                      large
                    color="grey"
                    class="
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          justify-center
                        "
                    @click="$router.push({ name: 'Dashboard' })"
                  >Back</v-btn>
                  <v-btn
                      dark
                      large
                    color="red"
                    class="
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          justify-center
                        "
                    @click="redoDomain"
                  >Redo</v-btn>
                   <v-btn
                      dark
                      large
                    color="green"
                    class="
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          justify-center
                        "
                    @click="verifyDomain"
                  >Verify</v-btn>
                </v-card-actions>
                    </v-col>

                    
                  </v-row>
                </v-card-text>
                <v-card-text style="margin-bottom: -30px;" v-if="domain.id && domain.status == 1">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Custom Domain</h5>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h3><v-icon style="color:green;font-size: 18px;">fa-check-circle</v-icon>  Your Custom Domain is activated</h3>
                     <h1 class="mt-5" style="color: #4CAF50">{{domain.domain}}</h1>

                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                  
                
                  

                  </v-col>
                    <v-col
                    cols="12"
                    md="12"
                    >
                    <v-card-actions class="justify-center">
                  <v-btn
                      dark
                      large
                    color="grey"
                    class="
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          justify-center
                        "
                    @click="$router.push({ name: 'Dashboard' })"
                  >Back</v-btn>
                  <v-btn
                      dark
                      large
                    color="red"
                    class="
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          justify-center
                        "
                    @click="redoDomain"
                  >Redo</v-btn>
                </v-card-actions>
                    </v-col>

                    
                  </v-row>
                </v-card-text>
                <!-- <v-card-actions class="justify-center ">
                  <v-btn
                    tile  dark
                    style="height: 49px;width:95px;"
                    color="primary"
                    class="mb-10"
                    @click="saveProfile"
                  >Save</v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
          <v-dialog
        fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="openResize"
    >

     <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="openResize = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Resize Logo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>

          </v-toolbar-items>
        </v-toolbar>
        <div>
               <iframe
                                id="JotFormIFrame-90142788412154"
                                title="Trainee Registration Form"
                                onload="window.parent.scrollTo(0,0)"
                                allowtransparency="true"
                                allowfullscreen="true"
                                allow="geolocation; microphone; camera"
                                src="https://www.countingcharacters.com/image-resizer-online#sectionbg"
                                frameborder="0"
                                style="width: 1px;
                                min-width: 100%;
                                height:1000px;
                                border:none;"
                                scrolling="yes"
                                ></iframe>
        </div>
      </v-card>
    </v-dialog>
    </v-container>
    
     <!-- <vue-footer></vue-footer> -->
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import HtmlEditor from "../HtmlEditor.vue";
import Editor from '@tinymce/tinymce-vue'
export default {
components: {
      VueTelInput, HtmlEditor,'editor': Editor
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        name:'',
        phone:'',
        password : '',
        is_admin:0,
        confpassword : '',
        openResize: false,
        role : '',
        domain : {
          domain : '',
          id: ''
        },
        companyname : '',
        sector : '',
        no_of_employee : '',
        limit_letter: 10,
        server_ip: process.env.VUE_APP_SERVER_IP,
        showPassword : false,
        page_letter : 1,
        itemsPerPage_letter: 10,
        pageCountLetter : 1,
        letters : [],
        datatable_options_letter : {},

        totalCountLetter : 0,
        phoneFlag:0,
        headers_letter : [
           { text: 'Title', value: 'title',
            sortable: true },
          { text: 'Category', value: 'category',
            sortable: true },
          { text: 'Staus', value: 'status',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            { text: 'Edit', value: 'edit',
            sortable: true,width: '200' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        emailFlag:0,
        preview: null,
      image: null,
        country :'',
        otp_email:'',
        otp_phone:'',
        stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
        company : {
          name: "",
          contact_email: "",
          contact_phone: "",
          details:"",
          email: "",
          password: "",
          password_confirmation: "",
          address: '',
          city: '',
          state: '',
          zip:'',
          question_1 : '',
          question_2 : '',
          question_3 : '',
          question_4 : '',
          question_5 : '',
          logo : '',
          backgroung_logo : '',
          privacy_template : '',
          

        },
        default_template : '',
        previewbg :'',
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        user_email:'',
        status:0,

        tab: null,
        headers: [

          { text: '', value: 'CompanyURL' },
          { text: '', value: 'CompanyName' },

        ],
        history: [

        ],

        setting: [
        ],

        items: [
              'Admin',
              'Viewer',
      ],
      select_role : 'Admin',
       sectors: [

      ],
      employeeNumber: [
              '10-50',
              '50-100',
              'More than 100',

      ],

      }
    },

    methods: {
    addPhone(){
        this.phoneFlag = 1;
    },
    addEmail(){
        this.emailFlag = 1;
    },
    countryChanged(country) {
        this.country = '+' + country.dialCode
    },
    getAddressData: function (addressData, placeResultData, id) {
            this.company.address_1 = addressData.name;
            this.company.city = addressData.locality;
            this.company.state = addressData.administrative_area_level_1;
            this.company.zip = addressData.postal_code
        },
    uploadLogo(){
      const data = new FormData();
        data.append('file_logo', this.company.logo);
        axios({
          method: "POST",
          url: "/api/upload-logo",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          Vue.$toast.success("Logo uploaded", {
              position: 'top-right'
              });
              console.log();
              localStorage.setItem('company_logo',process.env.VUE_APP_WASBI_URL+response.data.data)
          this.loadingUpload = false;

          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      
    },
    uploadLogoBG(){
      const data = new FormData();
        data.append('file_logo', this.company.backgroung_logo);
        axios({
          method: "POST",
          url: "/api/upload-logo-background",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          Vue.$toast.success("Logo uploaded", {
              position: 'top-right'
              });
              console.log();
              localStorage.setItem('company_logo_bg',process.env.VUE_APP_WASBI_URL+response.data.data)
          this.loadingUpload = false;

          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      
    },
    removeLogoBG(){
      if(confirm('Are you sure? you want to remove.')){
        axios({
          method: "POST",
          url: "/api/remove-logo-bg",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {}
          })
          .then(response => {
          Vue.$toast.success("Logo removed", {
              position: 'top-right'
              });
              localStorage.setItem('company_logo_bg','')
              this.previewbg = ''
          this.loadingUpload = false;

          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      }
      
    },
    removeLogo(){
        if(confirm('Are you sure? you want to remove.')){
        axios({
          method: "POST",
          url: "/api/remove-logo",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {}
          })
          .then(response => {
          Vue.$toast.success("Logo removed", {
              position: 'top-right'
              });
              localStorage.setItem('company_logo','')
              this.preview = ''
          this.loadingUpload = false;

          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
        }
      
    },
    verifyDomain(){
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .post("/api/verify-custom-domain",{server_ip : this.server_ip},header)
        .then(response => {
            let data = response.data.data;
            
           if (response.data.flag == 1) {
                //this.domain = data
                this.getUserProfile();
                Vue.$toast.success('All good, wait 24 hours for domain to be acctivated.', {
                                    position: 'top-right'
                                })
               
            }else {
                Vue.$toast.error("Domain is not activated. Please try again later", {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    redoDomain(){
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .post("/api/redo-custom-domain",{server_ip : this.server_ip},header)
        .then(response => {
            let data = response.data.data;
            
           if (response.data.flag == 1) {
                //this.domain = data
                this.getUserProfile();
                Vue.$toast.success('Domain Removed.', {
                                    position: 'top-right'
                                })
                  location.reload();
               
            }else {
                // Vue.$toast.error("Domain is not activated. Please try again later", {
                // position: 'top-right'
                // });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    verify(){
    let self = this;
    let flag = 1;
    if (!self.email && self.emailFlag ==1) {
    Vue.$toast.error('Email '+this.$t('questionnaire.questionnaire_alert_required'), {
        position: 'top-right'
    });
    this.$refs['email'].focus()
    flag = 0;
    }
    let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (reg.test(self.email) == false && flag == 1 && self.emailFlag == 1) {
        Vue.$toast.error('Invalid Email format', {
        position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
    }
    if (!self.phone && flag == 1 && self.phoneFlag == 1) {
    Vue.$toast.error('Phone Number '+this.$t('questionnaire.questionnaire_alert_required'), {
        position: 'top-right'
    });
    this.$refs['phone'].focus()
    flag = 0;
    }

    if (flag == 1) {
    var email = this.email;
    axios({
        method: "POST",
         
        url: "/api/auth/send_otp",
        data: {
        flag : self.phoneFlag == 1 ? 'phone' : 'email',
        email: self.email,
        phone:  self.phone,
        code: self.country,
        logged_flag : 1
        }
    })
        .then(response => {
        let data = response.data;
        if (data.data.flag == 1) {
            if(self.phoneFlag == 1 ){
                self.otp_phone = data.data.otp;
                self.phoneFlag = 2;
            }
            else{
                self.otp_email = data.data.otp;
                self.emailFlag = 2;
            }
            Vue.$toast.success(data.message, {
                position: 'top-right'
            });

        } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
        })
        .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
        });
      }
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        this.company.logo = input.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        }
        this.image=input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewImageBG: function(event) {
      var input = event.target;
      console.log(input);
      if (input.files) {
        this.company.backgroung_logo = input.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.previewbg = e.target.result;
        }
        this.image=input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    verifyOTP(){
        if(this.phoneFlag ==2){
            if(! this.otp_phone_input){
                Vue.$toast.error('OTP '+this.$t('questionnaire.questionnaire_alert_required'), {
                    position: 'top-right'
                });
            }else{
                if(this.otp_phone == this.otp_phone_input){
                    axios({
                        method: "POST",
                         
                        url: "/api/updateprofile",
                        data: {
                            flag: 'phone',
                            phone: this.phone,
                            code: this.country
                        }
                        })
                        .then(response => {
                            let data = response.data;
                            if (data.data == 1) {
                                Vue.$toast.success(data.message, {
                                    position: 'top-right'
                                })
                                this.getUserProfile()
                                this.phoneFlag = 3;
                            } else {
                                Vue.$toast.error(data.message, {
                                position: 'top-right'
                                });
                            }
                        })
                        .catch(err => {
                            Vue.$toast.error(err, {
                            position: 'top-right'
                            });
                        });


                }else{
                    Vue.$toast.error('Invalid OTP', {
                        position: 'top-right'
                    });
                    this.otp_phone_input ="";
                    this.$refs['phone_otp'].focus()
                }
            }

        }else{
            if(! this.otp_email_input){
                Vue.$toast.error('OTP '+this.$t('questionnaire.questionnaire_alert_required'), {
                    position: 'top-right'
                });
            }else{
                if(this.otp_email == this.otp_email_input){
                     axios({
                        method: "POST",
                         
                        url: "/api/updateprofile",
                        data: {
                            flag: 'email',
                            email: this.email,
                        }
                        })
                        .then(response => {
                            let data = response.data;
                            if (data.data == 1) {
                                Vue.$toast.success(data.message, {
                                    position: 'top-right'
                                })
                                //this.$router.push("/home");
                                this.emailFlag = 3;
                                this.getUserProfile()
                            } else {
                                Vue.$toast.error(data.message, {
                                position: 'top-right'
                                });
                            }
                        })
                        .catch(err => {
                            Vue.$toast.error(err, {
                            position: 'top-right'
                            });
                        });

                } else{
                    Vue.$toast.error('Invalid OTP', {
                        position: 'top-right'
                    });
                    this.otp_email_input ="";
                    this.$refs['email_otp'].focus()
                }
            }
        }
    },
      changePassword: function () {
          let flag = 1;
          if (!this.password) {
            Vue.$toast.error('Password '+this.$t('questionnaire.questionnaire_alert_required'), {
                position: 'top-right'
            });
            this.$refs['password'].focus()
            flag = 0;
          }

          if (!this.confpassword) {
            Vue.$toast.error('Confirm Password '+this.$t('questionnaire.questionnaire_alert_required'), {
                position: 'top-right'
            });
            this.$refs['confpassword'].focus()
            flag = 0;
          }
          if (this.confpassword != this.password && flag == 1) {
            Vue.$toast.error('Confirm password did not match', {
                position: 'top-right'
            });
            self.$refs['conf_password'].focus()
            flag = 0;
        }

          if (flag == 1) {

            axios({
            method: "POST",
             
            url: "/api/passwordchange",
            data: {
            password: this.password,
            password_confirmation: this.confpassword,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.data == 1) {
              Vue.$toast.success(this.$t('my_profile.profile_alert_password_change_successfull'), {
                position: 'top-right'
                });

            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }

    },

    add: function () {
         // for setting tab
    },

    getUserProfile() {
    let flag = 1;
    let id  = localStorage.getItem('company_id');
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .post("/api/get-custom-domain",header)
        .then(response => {
            let data = response.data.data;
            
           if (flag == 1) {
              if(data)
                this.domain = data
               
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
     getLetters(){
      this.loading = true;
            var offset = (this.page_letter - 1) * 10
            let req = {
              limit : this.limit_letter,
              offset : offset,
              sort : this.datatable_options_letter.sortBy,
              sortDesc : this.datatable_options_letter.sortDesc && this.datatable_options_letter.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search_letter
            }
            axios({
          method: "POST",
           
          url: "/api/get-all-letter",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.letters = data.data;
                this.pageCountLetter = data.total_page
                this.totalCountLetter = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    deleteItemLetter(id){
       
       var r = confirm("Are you sure, You went to delete?");
       if (r == true) {
         axios
           .post("/api/delete-letter/"+id,this.header)
           .then(response => {
               let data = response.data;

           if (response.data) {
               Vue.$toast.success('Deleted successfully', {
                   position: 'top-right'
                   });
                 this.getLetters();
               }else {
                   Vue.$toast.error(data.message, {
                   position: 'top-right'
                   });
               }

           })
         .catch(err => {
           Vue.$toast.error(err, {
           position: 'top-right'
           });
         })
       }
     },



    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let id  = localStorage.getItem('company_id');
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.domain.domain) {
            Vue.$toast.error('Domain Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        let regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
  
        if (!regexp.test(this.domain.domain) && flag == 1){
          Vue.$toast.error('Domain Name is not valid. Please use domain like xyz.com', {
                position: 'top-right'
            });
            flag = 0;
        }
        
        if (flag == 1) {
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/save-custom-domain",
          data: self.domain
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Domain saved', {
                    position: 'top-right'
                  })
                  this.getUserProfile()
                  // this.$router.push('/company-profiles')

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            
              if(err.response.data.errors.domain)
                Vue.$toast.error(err.response.data.errors.domain[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },

 },
 watch: {
  datatable_options_letter: {
                handler () {
                this.getLetters()
                },
                deep: true,
            },
 },
created() {
      console.log('created called.');
      this.getUserProfile();
    },

}
</script>

