<template>
  <v-app class="bg-white">
    <v-overlay :value="!company">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-main class="auth-pages" v-if="company" >
      
      <section style="background-color: #021033;" class="elementor-section elementor-top-section elementor-element elementor-element-71aad34 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="71aad34" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;_ha_eqh_enable&quot;:false}">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-42257f6" data-id="42257f6" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-8e985a6 elementor-widget elementor-widget-image" data-id="8e985a6" data-element_type="widget" data-widget_type="image.default">
				<div class="p-5" style="justify-content: center;display: flex;padding: 15px;">
											<a href="#">
							<img src="https://s3.us-central-1.wasabisys.com/quantumresolution/1cQYZ1f99KL1qZzx2svfwsdAUFg5GCsqe/file_e4677fb78967712d78928751a9a662e2.png"  :height="this.$vuetify.breakpoint.xs ? 50 : 80">								</a>
															</div>
				</div>
					</div>
		        </div>
							</div>
		      </section>
          <div class="header" id="myHeader">
            <div class="elementor-container elementor-column-gap-default" style="width: 100%;">
              <v-row style="margin:0px!important;">
                <v-col>
					<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-a828a20" data-id="a828a20" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-88f37c7 elementor-view-default elementor-widget elementor-widget-icon" data-id="88f37c7" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" :href="phone">
			<i aria-hidden="true" class="fas fa-comment-alt"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-f383dda elementor-widget elementor-widget-heading" data-id="f383dda" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title elementor-size-default"><a :href="phone">Text Me</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    <v-col>
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-1eed04c" data-id="1eed04c" data-element_type="column" id="share-my-card" @click="copyURL">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-61dd731 elementor-view-default elementor-widget elementor-widget-icon" data-id="61dd731" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" href="#">
			<i aria-hidden="true" class="fas fa-share"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-08fcb6e elementor-widget elementor-widget-heading" data-id="08fcb6e" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title elementor-size-default"><a href="#">Share</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    <v-col v-if="zoom_value">
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-bbc1548" data-id="bbc1548" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-575f36e elementor-view-default elementor-widget elementor-widget-icon" data-id="575f36e" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" :href="zoom_value" target="_blank">
			<i aria-hidden="true" class="fas fa-video"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-a105ee9 elementor-widget elementor-widget-heading" data-id="a105ee9" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title elementor-size-default"><a :href="zoom_value" target="_blank">Zoom Meeting</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    <v-col>
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-d7c2c7a" data-id="d7c2c7a" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-5f80995 elementor-view-default elementor-widget elementor-widget-icon" data-id="5f80995" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" @click="addCard(company.items)">
			<i aria-hidden="true" class="fas fa-user-plus"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-2432e95 elementor-widget elementor-widget-heading" data-id="2432e95" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title elementor-size-default"><a @click="addCard(company.items)">Add</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    <v-col v-if="event_value">
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-5b2a404" data-id="5b2a404" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-57f7405 elementor-view-default elementor-widget elementor-widget-icon" data-id="57f7405" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" :href="event_value" target="_blank">
			<i aria-hidden="true" class="fas fa-calendar-check"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-eb196fc elementor-widget elementor-widget-heading" data-id="eb196fc" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title elementor-size-default"><a :href="event_value" target="_blank">Events</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    </v-row>
							</div>
          </div>











          <div class="footer" id="myHeader">
            <div class="elementor-container elementor-column-gap-default" style="width: 100%;">
              <v-row style="margin:0px!important;">
                <v-col>
					<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-a828a20" data-id="a828a20" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-88f37c7 elementor-view-default elementor-widget elementor-widget-icon" data-id="88f37c7" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" :href="phone">
			<i aria-hidden="true" class="fas fa-phone-alt"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-f383dda elementor-widget elementor-widget-heading" data-id="f383dda" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title elementor-size-default"><a :href="phone">Energy Call</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    <v-col>
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-1eed04c" data-id="1eed04c" data-element_type="column" id="share-my-card" @click="gotopayemnt">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-61dd731 elementor-view-default elementor-widget elementor-widget-icon" data-id="61dd731" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" href="#">
			<i aria-hidden="true" class="fas fa-dollar-sign"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-08fcb6e elementor-widget elementor-widget-heading" data-id="08fcb6e" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title elementor-size-default"><a href="#">Payments</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    <v-col>
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-bbc1548" data-id="bbc1548" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-575f36e elementor-view-default elementor-widget elementor-widget-icon" data-id="575f36e" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
			<a class="elementor-icon" href="#" target="_blank">
        <img src="https://s3.us-central-1.wasabisys.com/quantumresolution/1cQYZ1f99KL1qZzx2svfwsdAUFg5GCsqe/file_cf2e0e9585d3f376fd86523e60034e05.jpeg" style="width: 55px;
          border-style: none;
          height: 55px;
          border-radius: 100px 100px 100px 100px;
          box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);" class="attachment-full size-full wp-image-912" alt="" decoding="async" loading="lazy"  sizes="(max-width: 1024px) 100vw, 1024px" width="1024" height="1024">			</a>
				</div>
				</div>

					</div>
		</div>
    </v-col>
    <v-col>
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-d7c2c7a" data-id="d7c2c7a" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-5f80995 elementor-view-default elementor-widget elementor-widget-icon" data-id="5f80995" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" @click="addCard(company.items)">
			<i aria-hidden="true" class="fas fa-user-plus"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-2432e95 elementor-widget elementor-widget-heading" data-id="2432e95" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title elementor-size-default"><a @click="addCard(company.items)">Add</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    <v-col v-if="event_value">
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-5b2a404" data-id="5b2a404" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-57f7405 elementor-view-default elementor-widget elementor-widget-icon" data-id="57f7405" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" :href="event_value" target="_blank">
			<i aria-hidden="true" class="fas fa-calendar-check"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-eb196fc elementor-widget elementor-widget-heading" data-id="eb196fc" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title elementor-size-default"><a :href="event_value" target="_blank">Events</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    </v-row>
							</div>
          </div>
          <section class="elementor-section elementor-top-section elementor-element elementor-element-a14cd74 elementor-section-height-min-height elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-items-middle" data-id="a14cd74" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;video&quot;,&quot;background_video_link&quot;:&quot;https:\/\/player.vimeo.com\/video\/68384616?h=b4d1b9c66e&quot;,&quot;background_play_on_mobile&quot;:&quot;yes&quot;,&quot;shape_divider_bottom&quot;:&quot;triangle&quot;,&quot;shape_divider_bottom_negative&quot;:&quot;yes&quot;,&quot;_ha_eqh_enable&quot;:false}" :style="company.background ? 'background-image: url('+background+'); background-size: cover;  background-position: center;-repeat: no-repeat;' : ''">
								<div class="elementor-background-video-container" data-vimeo-initialized="true" v-if="company.video_background && company.video_background != 'null'">
													<div class="elementor-background-video-embed"></div>
												<iframe :src="'https://player.vimeo.com/video/'+vimeo_id+'?h='+vimeo_account+'&amp;muted=1&amp;autoplay=1&amp;loop=1&amp;transparent=0&amp;background=1&amp;app_id=122963'" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" title="New York Aerial Footage" data-ready="true" class="elementor-background-video-embed elementor-background-video-hosted" style="width: 2144px; height: 1206px;" width="480" height="270" frameborder="0"></iframe></div>
							<div class="elementor-shape elementor-shape-bottom" data-negative="true">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
	<path class="elementor-shape-fill" d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"></path>
</svg>		</div>
					<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1d6f932" data-id="1d6f932" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-09cffa4 elementor-widget elementor-widget-image" data-id="09cffa4" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
															<img :src="company.api_ref_id  && company.api_ref_id != 'null' ? company.photo : base_url_img+'vcard/'+company.photo" class="attachment-full size-full wp-image-689" alt="" decoding="async" loading="lazy"  sizes="(max-width: 600px) 100vw, 600px" width="600" height="600" :style="company.photo_height && company.photo_width ? 'height: '+ company.photo_height+'px; width:'+ company.photo_width+'px' : ''">															</div>
				</div>
					</div>
		</div>
							</div>
		</section>
    <v-row style="display: flex; justify-content: space-around;margin:0px!important;" >
                <v-col cols="12" style="display: flex; justify-content: space-around; margin-top: 25px; " >
                    <h1 class="elementor-heading-title2 " style="color: black">{{company.name}}</h1>
                  </v-col>
                  <v-col cols="12" style="display: flex; justify-content: space-around" >
                    <p  style="color: black;margin-top: -15px;">{{company.position}}</p>
                  </v-col>
                  <v-col cols="12"  style="display: flex;margin-top: -25px; justify-content: space-around; margin-bottom: 35px;" >
                    <h5 class="h4 font-weight-medium" style="color: black;opacity: 0.55;">{{company.business_name}}</h5>
                  </v-col>
                </v-row>
                <div class="elementor-social-icons-wrapper elementor-grid" style="display: inline-block;text-align: center;
width: 100%;
word-spacing:10px;">
							<span class="elementor-grid-item" v-for="item in company.items" :key="item.id" v-show="item.type != 'Stripe' && item.type != 'Cash App' && item.type != 'Venmo' && item.type!='Zelle Transfer' && item.type != 'Peymynt' && item.type != 'Paypal'">
                <a class="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-repeater-item-3af8d70" :href="item.link" target="_blank">
                  <span class="elementor-screen-only">Instagram</span>
                  <i :class="getIcon(item.type, item.link)"></i>					</a>
              </span>
							
					</div>
          <div class="container1" :style="this.$vuetify.breakpoint.xs ? 'margin-bottom:-120px;margin-top:-70px' : 'margin-bottom: 60px !important;margin-top:20px'" v-if="videos.length > 0">
          <button type="button" @click="prevSlide(index)" style="margin-left: 30px;"><i class="fas fa-chevron-left"></i></button>
          <div class="slider">
            <div class="slide1" v-if="index == key" v-for="(video,key) in videos" :key="video.id">
              <iframe :src="'https://player.vimeo.com/video/'+video.vimeo_id+'?h='+video.vimeo_account+'&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;'" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Connors bestfriend"></iframe>
            </div>
          </div>
          <button type="button" @click="nextSlide(index)" style="margin-right: 30px;"><i class="fas fa-chevron-right"></i></button>
        </div>
        <v-row style="justify-content: center;margin:0px!important;" >
          <v-col cols="11" md="6">
        <v-carousel v-model="model" :style="this.$vuetify.breakpoint.xs ? 'margin-bottom: 100px !important;' : 'margin-bottom: 160px !important;'" height="auto" width="auto">
          <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
            :src="item.src"
          >
            
          </v-carousel-item>
        </v-carousel>
        </v-col>
        <v-col cols="12" md="12" style="margin-bottom: 100px !important;margin-top: -80px;">
          <div class="elementor-widget-container">
			      <h2 class="elementor-heading-title elementor-size-default " style="margin-bottom:45px; font-family: 'Lora', Sans-serif; font-size: 32px; font-weight: 600;text-transform: capitalize; line-height: 40px;">The modern approach to business cards</h2>		</div>
          <div class="elementor-widget-container">
					<div class="elementor-button-wrapper">
            <a href="/" class="elementor-button-link elementor-button elementor-size-sm" role="button">
                  <span class="elementor-button-content-wrapper">
                  <span class="elementor-button-text">Check out my Digital&nbsp;Card</span>
          </span>
                </a>
          </div>
              </div>
              <div class="elementor-element elementor-element-99447f3 elementor-align-center elementor-tablet-align-center elementor-mobile-align-center elementor-widget elementor-widget-button" data-id="99447f3" data-element_type="widget" data-widget_type="button.default">
				<div class="elementor-widget-container" style="margin-top :50px;">
					<div class="elementor-button-wrapper">
			<a href="#" class="elementor-button-link elementor-button elementor-size-sm" role="button">
						<span class="elementor-button-content-wrapper">
						<span class="elementor-button-text">Sean's Payment&nbsp;links</span>
		</span>
					</a>
		</div>
				</div>
				</div>
        <div class="elementor-widget-container" style="margin-top :50px;">
					<div class="elementor-button-wrapper">
			<a  @click="overlay = true" data-model-cid="c84551" class="elementor-button-link elementor-button elementor-size-sm" role="button">
						<span class="elementor-button-content-wrapper">
						<span class="elementor-button-text">Scan my QR Code</span>
		</span>
					</a>
		</div>
				</div>
        </v-col>
        </v-row>
        








                  <v-dialog
                      v-model="overlay"
                      width="500"
                    >
                    <img v-if="company"  class="" :src="company.qr_image ? (company.api_ref_id  && company.api_ref_id != 'null' ? company.qr_image : base_url_img+'vcard/'+company.qr_image) : base_url_img+'vcard/'+company.id+'-QR.png'" width="50">
                  
                  
                  </v-dialog>
           
    </v-main>
  </v-app>
</template>
<script>
var slides = document.querySelectorAll(".slide");
var currentSlide = 0;


import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import VCard from 'vcard-creator'
import { FadeTransition } from "vue2-transitions";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
    VCard
  },
  data() {
    return {
       checkbox: false,
       showpassword : false,
       email : '',
      password : '',
      phone : '',
      background: '',
      vimeo_account: '',
      vimeo_id : '',
      overlay : false,
      index: 0,
      showPassword : false,
      zoom_value : '',
      event_value :'',
      dialog: false,
      videos : [],
      pageFlag: 'init',
      backgroundLogo: localStorage.getItem('admin_bg'),
      isDark :true,
      buttonFlag   : false,
      loadingLogo : false,
      url : '',
      items: [
        ],
      company : '',
      loadingFlag : false,
      base_url_img : process.env.VUE_APP_WASBI_URL
    };
  },
  beforeCreate: function () {
    
},
created: function () {
  
      this.getCompanyInfo(this.url)

},
mounted : function () {

},
methods: {
  prevSlide(index) {
  if(index !=0 )
    this.index = index - 1;
  else
    this.index = this.videos.length - 1;

},

nextSlide(index) {
  if(index != this.videos.length - 1 )
    this.index = index + 1
  else
    this.index = 0
},
gotopayemnt: function () {
    let self = this;
    self.$router.push('/card-payment/'+this.$route.params.id);
},
getCompanyInfo(){
  let id = this.$route.params.id;
  axios({
          method: "POST",
           
          url: "/api/get-card",
          data: {
            url: id
          }
        })
          .then(response => {
            this.company = response.data.data;
            this.background = response.data.data.api_ref_id  && response.data.data.api_ref_id != 'null' ? this.company.background :  this.base_url_img+'vcard/'+this.company.background
            this.company.items = JSON.parse(JSON.parse(this.company.items))
            if(this.company.video_background){
              let video_back = this.company.video_background.split("/");
              this.vimeo_id = video_back[0]
              this.vimeo_account = video_back[1]
            }
            let url = process.env.VUE_APP_WASBI_URL.replace('/logo','')
            if(response.data.images.length > 0){
              response.data.images.forEach(image => {
                this.items.push({
                  src: url+image.folder_id+'/'+image.file_name
                })
              })
            }
            if(response.data.videos.length > 0){
              response.data.videos.forEach(video => {
                let link = video.vimeo_id.split("/");
                // let mytext = '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/'+link[0]+'?h='+link[1]+'&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;  height:100%;" title="'+video.title+'"></iframe></div><scri'+'pt src="https://player.vimeo.com/api/player.js"></scri'+'pt>' ;
                this.videos.push({
                  id: video.id, vimeo_id : link[0], vimeo_account : link[1]
                })
              })
            }
            //this.loadingFlag = true;
          })

},
async copyURL() {
  console.log(window.location);
      try {
        await navigator.clipboard.writeText(window.location);
        alert('URL Copied, Now you can share the URL');
      } catch($e) {
        alert('Cannot copy');
      }
    },
checkFormSignup(){
  let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        localStorage.setItem('temp_email',this.email)
        localStorage.setItem('temp_pass',this.password)
        localStorage.setItem('email_verified',0)
        if(this.company)
          this.$router.push('/signup/'+this.url)
        else
          this.$router.push('/signup')
      }
},
forgotPass(){
    this.buttonFlag =true;
    this.isDark = false;
    axios({
          method: "POST",
           
          url: "/api/auth/forgot-password",
          data: {
            email: this.email,
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Forgot Password Mail sent', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.isDark = true;
                this.pageFlag = 'login';
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
},
gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},gotoGoogle(){
      let self = this;
        this.$router.push('/redirect/google');
        location.reload();
  },
  gotoMs(){
      let self = this;
        this.$router.push('/redirect/microsoft');
        location.reload();
  },
  gotoYahoo(){
      let self = this;
        this.$router.push('/redirect/yahoo');
        location.reload();
  },
  exportToFile(card, name='contact', force=true) {
            const a = document.createElement('a');
            const file = new Blob([card], { type: 'text/vcard'});

            a.href = URL.createObjectURL(file);
            a.download = name;
            a.click();

            URL.revokeObjectURL(a.href);
  },
  getIcon(id,value){
    if(id == 'Phone'){
      this.phone = value
      return 'fa fa-phone';
    }
    if(id == 'Zoom'){
      this.zoom_value = value
      return 'fas fa-video';
    }
    if(id == 'Event'){
      this.event_value = value
      return 'fas fa-calendar-check';
    }
    if(id == 'Email')
      return 'fas fa-envelope';
    if(id == 'Whatsapp')
      return 'fab fa-whatsapp';
    if(id == 'SMS/Text')
      return 'fas fa-comments';
    if(id == 'Website')
      return 'fas fa-link';
    if(id == 'Instagram')
      return 'fab fa-instagram';
    if(id == 'Snapchat')
      return 'fab fa-snapchat';
    if(id == 'Twitter')
      return 'fab fa-twitter';
    if(id == 'Facebook')
      return 'fab fa-facebook';
    if(id == 'LinkedIN')
      return 'fab fa-linkedin';
    else
      return 'fas fa-link';
  },
/**
 * checkForm
 * login the user with valid email/pass
 */
addCard(value) {

            // user.instagram = "http://instagram.com/"+ user.instagram
            // user.facebook = "http://www.facebook.com/profile.php?id=" + user.facebook
            // user.youtube = "http://www.youtube.com/user/"+ user.youtube
            // user.linkedin = "http://www.linkedin.com/in/"+ user.linkedin
            // user.website = "http://instagram.com/"+ user.instagram
            // user.twitter = "http://twitter.com/"+ user.twitter
            // user.pinterest= "http://pinterest.com/"+ user.pinterest
            // user.tiktok= "http://www.tiktok.com/@"+ user.tiktok
            // user.snapchat=  "http://snapchat.com/add/"+ user.snapchat
            // user.reddit= "http://www.reddit.com/user/"+ user.reddit
            // user.tumblr= "http://tumblr.com/"+ user.tumblr
            // user.flickr= "http://www.flickr.com/photos/"+ user.flickr
            // user.vine= "http://vine.co/"+ user.vine
            // user.medium= "http://medium.com/@"+ user.medium
            // user.soundcloud= "http://soundcloud.com/"+ user.soundcloud
            // user.twitch= "http://www.twitch.tv/"+ user.twitch
            // user.clubhouse= " https://www.joinclubhouse.com/@"+ user.clubhouse
            const myVCard = new VCard()
            myVCard.addName(this.company.name)
            .addCompany(this.company.business_name)
            this.company.items.forEach(element => {
              if(element.type == 'Email')
                myVCard.addEmail(element.link.replace('mailto:',''))
              else if(element.type == 'Phone')
                myVCard.addPhoneNumber(element.link.replace('tel',''))
              else if(element.type == 'SMS/Text')
                myVCard.addPhoneNumber(element.link.replace('sms:',''))
              else
                myVCard.addSocial(element.link, element.label)
            
            });
            const output = myVCard.toString()
            console.log(output);
            const preElement = document.querySelector('#output')
            const outputNode = document.createTextNode(output)
            this.exportToFile(output,'contact',true)
          
    }
  }
    
};
</script>
<style>
  .check-login .v-label {
    color: white;
  }
  .check-login .v-icon {
    color: white;
  }
.input-login .v-icon{
    color: #FDE038!important;
}
.input-login .v-label{
  color: #FDE038!important;
}
.input-login #input-6{
  color: #FDE038!important;
}
.input-login #input-10{
  color: #FDE038!important;
}
.header-card{
  overflow: hidden;
  position: relative;
  z-index: 3;
  border-bottom: 1px solid rgb(217, 218, 220);
  color: rgb(20, 20, 20);
  background: linear-gradient(45deg, rgb(255, 117, 140), rgb(255, 126, 179));
}
.cards{
  box-sizing: border-box;
  position: relative;
  display: block;
  padding: 80px 10px 30px;
  background-color: rgb(230, 230, 230);
  border-bottom: 1px solid rgb(217, 218, 220);
  text-align: center;
  cursor: pointer;
  user-select: none;
  color: rgb(20, 20, 20);
}
.cards:nth-child(2n+1) {
  border-right: 1px solid rgb(217, 218, 220);
}
.cards:hover {
  color: skyblue!important;
  background: #D9E6F4;
}
.header {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border: solid #fff;
  border-width: 0 1px;
  z-index: 1;
  background: white;
  font-weight: 700;
}
.footer {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  border: solid #fff;
  border-width: 0 1px;
  z-index: 1;
  background: white;
  font-weight: 700;
  width: 100%;
}
.elementor-container {
text-align: center;
}
.elementor-column {
  position: relative;
  min-height: 1px;
  display: flex;
  justify-content: center;
}
.elementor-icon-wrapper{
  font-size: 30px;
  color: #000000;
}
.elementor-element.elementor-element-09cffa4 img {
  width: 250px;
  height: 250px;
  border-style: solid;
  border-width: 5px 5px 5px 5px;
  border-color: #FFFFFF;
  border-radius: 300px 300px 300px 300px;
}
.elementor-element.elementor-element-a14cd74:not(.elementor-motion-effects-element-type-background), .elementor-581 .elementor-element.elementor-element-a14cd74 > .elementor-motion-effects-container > .elementor-motion-effects-layer {
  background: url("https://s3.us-central-1.wasabisys.com/quantumresolution/1cQYZ1f99KL1qZzx2svfwsdAUFg5GCsqe/file_dedcf61e70aadcc9c421d0400cba67e5.png") 50% 50%;
    background-size: auto;
  background-size: cover;
  padding: 60px 0px 70px 0px;
  position: relative;
}
.elementor-shape-bottom {
  bottom: -1px;
}
.elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}
.elementor-shape .elementor-shape-fill {
  fill: #fff;
  transform-origin: center;
  transform: rotateY(0deg);
}
.elementor-heading-title2 {
  color: #303030;
  font-family: "Lora", Sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}
 .elementor-social-icon {
  background-color: #021033;
}
.elementor-icon.elementor-social-icon {
  border-radius: 50%;
  font-size: 15px;
  line-height: 25px;
  width: 25px;
  display: inline-block;
  
  height: 25px;
}
.elementor-screen-only, .screen-reader-text, .screen-reader-text span, .ui-helper-hidden-accessible {
  position: absolute;
  top: -10000em;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
.elementor-widget-container {
  text-align: center;
}
.elementor-social-icon i {
  color: #FFFFFF;
}
.elementor-grid-item{
  margin: 5px;
}
.slider {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slide.active {
  visibility: visible;
  opacity: 1;
}

iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container1 {
  display: flex;
  align-items: center;
  gap: 8px;
}
 .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 17px;
  font-weight: 400;
  text-transform: capitalize;
  fill: var( --e-global-color-6aef213 );
  color: var( --e-global-color-6aef213 );
  background-color: #FEFEFE;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: var( --e-global-color-6aef213 );
  border-radius: 50px 50px 50px 50px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.32);
  padding: 14px 50px 14px 50px;
}

.elementor-background-video-container {

    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    z-index: 0;
    direction: ltr;
    transition: opacity 1s;
    pointer-events: none;

}
 .elementor-background-video-hosted {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
</style>